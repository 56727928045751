/**
 * [data-provide~="selectall"] の拡張
 */

const changeSelectallCount = function(count) {
  $('[data-provide~="selectall-count"]').text(count)
}

const enableActions = function($table, bool) {
  const actionTags = 'button, input, textarea, select'
  const $actions = $table.prev('header').find(actionTags)

  $actions.prop({ disabled: !bool })
}

$(document).on('change', '[data-provide~="selectall"] thead .custom-checkbox :checkbox', function(e) {
  const $this = $(this)
  const $table = $this.closest('table')
  const $checkboxes = $table.find('tbody .custom-checkbox :checkbox')
  const selectedCount = $this.prop('checked') ? $checkboxes.length : 0

  $this.prop({ indeterminate: false })
  changeSelectallCount(selectedCount)
  enableActions($table, !!selectedCount)
})

$(document).on('change', '[data-provide~="selectall"] tbody .custom-checkbox :checkbox', function(e) {
  const $table = $(this).closest('table')
  const $tbody = $table.find('tbody')
  const $checkboxSelectAll = $table.find('thead .custom-checkbox :checkbox')
  const $checkboxes = $tbody.find('.custom-checkbox :checkbox')
  const selectedCount = $checkboxes.filter(':checked').length

  changeSelectallCount(selectedCount)
  enableActions($table, !!selectedCount)

  if (!selectedCount) {
    $checkboxSelectAll.prop({ checked: false, indeterminate: false })
  } else {
    if (!$checkboxes.not(':checked').length) {
      $checkboxSelectAll.prop({ checked: true, indeterminate: false })
    } else {
      $checkboxSelectAll.prop({ checked: false, indeterminate: true })
    }
  }
})

$(document).on('click', '[data-provide~="selectall"] tr > *:first-child', function(e) {
  if (!['TH', 'TD'].includes(e.target.tagName)) return false

  const $checkbox = $(this).find('.custom-checkbox :checkbox')
  if (!$checkbox.length) return false

  $checkbox.prop('checked', !$checkbox.prop('checked')).trigger('change')
})
