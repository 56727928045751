// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("javascripts/channels")
require("select2/dist/css/select2")

import toastr from 'toastr'
import '../stylesheets/application.sass'
import '../javascripts/modules/dropify.js'
import '../javascripts/modules/file_group.js'
import '../javascripts/modules/table_media.js'
import '../javascripts/modules/selectall.js'
import '../javascripts/series.js'
import select2 from 'select2'

toastr.options = {
  'closeButton': false,
  'positionClass': 'toast-bottom-center',
}
global.toastr = toastr

// The Admin Plugin initialize
$(document).on('turbolinks:load', () => {
  const app = window.app
  app.initCorePlugins()
  if (window.Intercooler) {
    window.Intercooler.processNodes($('body'))
  }
})
$(document).on('turbolinks:before-cache', () => {
  $.fn.perfectScrollbar('destroy')
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
