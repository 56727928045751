function changeURL(input, target) {
  if (input.files && input.files[0]) {
    var reader = new FileReader()
    reader.onload = e => { target.src = e.target.result }
    reader.readAsDataURL(input.files[0])
  }
}

$(document).on('change', '.file-group [type=file]', function(e) {
  const $preview = $(this).closest('.file-group').prev('.image-preview')
  if (!$preview.length) return
  changeURL(this, $preview.find('img').get(0))
})
