import Turbolinks from 'turbolinks'

const onClick = (event) => {
  const target = event.target
  const exclude = ['A', 'INPUT', 'BUTTON', 'LABEL']
  const excludeClass = ['table-actions']

  if (excludeClass.some(v => target.classList.contains(v))) return
  if (target.closest(`.${excludeClass.join(', .')}`)) return

  if (!exclude.includes(target.tagName)) {
    const { href } = event.currentTarget.dataset
    if (href) Turbolinks.visit(href)
  }
}

document.addEventListener('turbolinks:load', () => {
  const items = document.querySelectorAll('.table-media [data-href]')
  items.forEach(v => v.addEventListener('click', onClick))
})
