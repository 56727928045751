window.app.ready(() => {
  $(document)
    .on('dropify.fileReady', '[data-provide="dropify"]', function() {
      $(this).parent('.dropify-wrapper').next('[type="hidden"]').val(0)
    })
    .on('dropify.afterClear', '[data-provide="dropify"]', function() {
      $(this).parent('.dropify-wrapper').next('[type="hidden"]').val(1)
    })
})

$(document).on('turbolinks:before-cache', () => {
  const dropify = $('[data-provide="dropify"]').data('dropify')

  if (dropify) {
    dropify.destroy()
  }
})