$(function() {
  $(document).on('click','.add_fields',function(event){
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $('#displayArea').append($(this).data('fields').replace(regexp, time))
    return event.preventDefault();
  });

  // 削除ボタン押下時イベント
  $(document).on('click','.remove_fields',function(event){
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').remove();
    return event.preventDefault();
  });
});
